import { Loader2 } from 'lucide-react'; // Import the Loader icon from Lucide

export default function LoadingIcon() {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <Loader2 className="animate-spin w-12 h-12 text-primary dark:text-primary" />
    </div>
  );
}

export function LoadingScreen() {
  return (
    <div className="flex items-center justify-center w-full h-full min-h-[80vh]">
      <LoadingIcon />
    </div>
  );
}
