import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/gt-next/dist/provider/ClientProviderWrapper.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/packages/services/platform/src/components/accounts/Redirecter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/packages/services/platform/src/components/animated/AnimatedButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FixedTypeWriter"] */ "/opt/render/project/src/packages/services/platform/src/components/animated/FixedTypeWriter.tsx");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/packages/services/platform/src/components/code/EnvExample.tsx");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/packages/services/platform/src/components/gt-ui/CodeBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/packages/services/platform/src/components/landing/GetStartedButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/packages/services/platform/src/components/landing/globe/SmallGlobe.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/packages/services/platform/src/components/landing/globe/Spotlight.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/packages/services/platform/src/components/landing/hero-text/ClientLanguageWriter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageCloudMarquee"] */ "/opt/render/project/src/packages/services/platform/src/components/landing/language-cloud/LanguageCloud.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageGrid"] */ "/opt/render/project/src/packages/services/platform/src/components/landing/language-grid/LanguageGrid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/packages/services/platform/src/components/landing/library/showcases/currency/FormattingShowcase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/packages/services/platform/src/components/landing/library/showcases/plurals/PluralShowcase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientPricingWithSwitch"] */ "/opt/render/project/src/packages/services/platform/src/components/landing/pricing/Pricing.tsx");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/packages/services/platform/src/components/ui/tabs.tsx");
