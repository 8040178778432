'use client';

import LoadingIcon, { LoadingScreen } from '@/components/animated/LoadingIcon';
import { useRouter } from 'next/navigation';

export default function Redirecter({ href }: { href: string }) {
  const router = useRouter();
  router.push(href);

  return (
    <main className="flex-1">
      <LoadingScreen />
    </main>
  );
}
